<template>
  <div>
    <img class="banner d-lg-block d-none" src="~assets/images/img/newsBanner.jpg"/>
    <div class="about container-fluid">
      <div class="row">
        <div class="col-12 col-lg-2" style="padding: 0">
          <switch-bar :switchList="switchList"
                      @switchItem="switchItem"
                      :activeIndex="Number(activeIndex)"/>
        </div>
        <div class="col-12 col-lg-9 aboutRight">
          <div class="rightTitle">
            <div class="rightTitleValue d-lg-block d-flex align-items-center">
              <p class="rightTitleValueC">{{showTitle}}</p>
              <p class="rightTitleValueE">{{showTitleE}}</p>
            </div>
            <div class="rightTitleLinks d-none d-lg-flex">
              <p>当前位置：</p>
              <router-link class="link" to="/home" tag="p">首页</router-link>
              >
              <!--刷新页面并且更新模块为第一块-->
              <router-link class="link" :to="{path:switchList.link,query:{index:0}}" tag="p">{{switchList.text}}
              </router-link>
              >
              <p class="linkLast">{{showTitle}}</p>
            </div>
          </div>
          <!--内容块-->
          <div class="WapBoxPadding " v-show="activeIndex==0" v-if="detail">
            <p class="newsDtlTitle">{{detail.right_news.title}}</p>
            <p class="newsDtlAuthorAndDate">
              <span>{{detail.right_news.date_added}}</span>
              <span>信息来源：{{detail.right_news.come}}</span>
            </p>
            <!--富文本-->
            <div class="richText" v-html="detail.right_news.description"></div>
            <!--富文本 END-->
            <div class="dtlBottom">
              <div class="dtlSwitch">
                <div class="dtlSwitchItem">
                  <p class="dtlSwitchItemText">上一篇：</p>
                  <router-link  :to="{path:'/newsdetail/'+detail.up_news.news_id,query:{index:activeIndex}}" tag="p" class="dtlSwitchItemValue" v-if="detail.up_news!=null">{{detail.up_news.title}}</router-link>
                  <p class="dtlSwitchItemValue" v-else>没有了</p>
                </div>
                <div class="dtlSwitchItem">
                  <p class="dtlSwitchItemText">下一篇：</p>
                  <router-link  :to="{path:'/newsdetail/'+detail.down_news.news_id,query:{index:activeIndex}}" tag="p" class="dtlSwitchItemValue" v-if="detail.down_news!=null">{{detail.down_news.title}}</router-link>
                  <p class="dtlSwitchItemValue" v-else>没有了</p>
                </div>
              </div>
              <router-link :to="{path:'/news',query:{index:0}}" tag="p" class="goBack">返回列表  →</router-link>
            </div>
          </div>
          <!--内容块 END-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import SwitchBar from "../../components/content/SwitchBar";
  import {getNewsDtl} from 'network/news';
  export default {
    name: "NewsDetail",
    components: {SwitchBar},
    data() {
      return {
        detail:null,
        //当前活跃的块下标
        activeIndex: 0,
      }
    },
    methods: {
      switchItem(index) {
        if (index != this.$route.query.index) {
          this.$router.push({
            path: this.$route.path,
            query: {
              index: index
            }
          })
        }
      },
    getNewsDtl(){
      getNewsDtl(this.$route.params.tid).then(res=>{
        this.detail=res.data;
      })
    }
    },
    computed: {
      switchList() {
        //从vuex中取出对应的数据 ==>store ==>index
        return this.$store.state.navInfo[4];
      },
      // 当前活跃块的中文标题
      showTitle() {
        return this.switchList.childList[this.activeIndex].title
      },
      // 当前活跃块的英文标题
      showTitleE() {
        return this.switchList.childList[this.activeIndex].titleE
      },

    },
    created() {
     this.getNewsDtl();
      this.activeIndex = this.$route.query.index;
    },
    watch: {
      '$route'() {
        this.activeIndex = this.$route.query.index;
        this.getNewsDtl();
      }
    }
  }
</script>
<style>
  .richText img {
    width: 100%;
    height: auto;
    margin-bottom: .4rem;
  }

  .richText p {
    font-size: .35rem;
    color: #a6a6a6;
    margin-bottom: .4rem;
  }
</style>
<style scoped>
  /* 小屏幕（平板，大于等于 768px） */
  @media (max-width: 992px) {
    .rightTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: .6rem 3%;
      border-bottom: 1px solid #e2e2e2;
    }

    .WapBoxPadding {
      padding: 0 3%;
    }
  }

  /* 中等屏幕（桌面显示器，大于等于 992px） */
  @media (min-width: 992px) {
    .rightTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: .35rem;
      border-bottom: 1px solid #e2e2e2;
    }

    .rightTitleValueC {
      margin-bottom: .2rem;
    }
  }
</style>
<style scoped>
  .dtlBottom {
    margin: 1.2rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }


  .dtlSwitchItem {
    display: flex;
    align-items: flex-start;
    font-size: .4rem;
    margin-bottom: .4rem;
  }

  .dtlSwitchItemText {
    color: #b2b2b2;
  }

  .dtlSwitchItemValue {
    color: #737373;
    flex: 1;
    padding-right: 2rem;
    cursor: pointer;
    transition: all .3s linear;
  }
  .dtlSwitchItemValue:hover{
    color: #2190E4;
    transition: color .6s;
  }
  .goBack{
    padding: .1rem .55rem;
    border-radius: 30px;
    border: 1px solid var(--red);
    color: var(--red);
    font-size: .4rem;
    cursor: pointer;
    margin-top: .6rem;
  }
  .newsDtlTitle {
    font-size: .5rem;
    color: #4c4c4c;
    margin-bottom: .4rem;
  }

  .newsDtlAuthorAndDate {
    font-size: .35rem;
    color: #a6a6a6;
    margin-bottom: .55rem;
  }

  .newsDtlAuthorAndDate span {
    margin-right: .6rem;
  }

  .banner {
    width: 100%;
    height: auto;
  }

  .about {
    padding: 1.65rem var(--switchMarginX);
  }

  .rightTitle {
    margin-bottom: .55rem;
  }

  .rightTitleValueC {
    font-size: .6rem;
    color: #474747;
    margin-right: .4rem;
  }

  .rightTitleValueE {
    font-size: .35rem;
    color: #b2b2b2;
    padding: 0;
  }

  .rightTitleLinks {
    display: flex;
    align-items: center;
    font-size: .35rem;
    color: #b2b2b2;
    cursor: pointer;
  }

  .rightTitleLinks .link:hover {
    color: var(--red);
    transition: color .3s;
  }

  .rightTitleLinks .linkLast {
    color: #737373;
  }
</style>